





























































import {Component, Vue} from "vue-property-decorator";
import {PolicySignature, PTOPolicy, PTOPolicyFilter} from "@/dto/payroll/policies/PTOPolicyDTO";
import Application from "@/state/Application";
import {processError} from "@/utils/ComponentUtils";
import DeleteConfirmation from "@/components/payroll/business/DeleteConfirmation.vue";
import PTOCreationModal from "@/components/payroll/policies/pto/PTOCreationModal.vue";
import PTOEditorModal from "@/components/payroll/policies/pto/PTOEditorModal.vue";
import moment from "moment/moment";
import {Moment} from "moment-timezone";
import PolicySignatureOptions from "@/components/payroll/policies/PolicySignatureOptions.vue";
import {prettyEnum} from "../../../../utils/StringUtils";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import RouteNames from "@/router/RouteNames";
import PolicyService from "@/services/payroll/PolicyService";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";

@Component({
	components: {BTPortalCollapse, SingleFileHolder},
	methods: {
		prettyEnum,
		moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): Moment {
			return moment(inp, format, language, strict)
		}
	}
})
export default class PTOPolicyList extends Vue {

	private ptoList: Array<PTOPolicy> = [];

	private totalPages = 0

	private filter: PTOPolicyFilter = new PTOPolicyFilter(
		{
			companyId: this.$wss.getCurrent.id
		}
	)

	public created(): void {
		this.loadPolicies()
	}

	private async loadPolicies(): Promise<void> {
		Application.startLoading()
		return PolicyService.getPageOfPTOPolicies(this.filter).then(
			res => {
				this.ptoList = res.data.data
				this.totalPages = res.data.countOfPages
				Application.stopLoading()
			},
			err => processError(err, this)
		)

	}

	private disable(pto: PTOPolicy) {
		this.$modal.show(
			DeleteConfirmation,
			{
				onDelete: () => this.disablePolicy(pto.id),
				targetName: pto.title,
				operationName: "disable"
			}
		)
	}



	private disablePolicy(id: number) {
		Application.startLoading()
		PolicyService.disablePTO(id).then(
			() => this.loadPolicies(),
			err => processError(err, this)
		)
	}

	private create() {
		this.$modal.show(
			PTOCreationModal,
			{
				callback: () => this.loadPolicies()
			}
		)
	}

	private edit(pto: PTOPolicy) {
		this.$modal.show(
			PTOEditorModal,
			{
				pto: pto,
				callback: () => this.loadPolicies()
			}
		)
	}

	private showOptions(signature: PolicySignature) {
		this.$modal.show(
			PolicySignatureOptions,
			{
				signature: signature,
				callback: () => this.loadPolicies(),
				mode: "PTO"
			}
		)
	}

	private toSignPage(policy: PTOPolicy) {
		this.$router.push({name: RouteNames.SIGN_PUBLIC, params: {publicId: policy.policyPublicId}})
	}
}

